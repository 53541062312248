import { BaseService } from "services/_baseService";
import appAxios from "services/axios";

class UsersService extends BaseService<any> {
  constructor() {
    super("users");
  }

  me = async () => {
    return await appAxios.get("/user");
  };
}

export default new UsersService();
