import ListPage from "components/ListPage/ListPage";
import { useRemoteResource } from "hooks/useRemoteResource";
import { listConfig } from "tableColumns/member-columns";
import AppAdminTable from "components/AppAdminTable/AppAdminTable";
import usersService from "services/admin/usersService";
import { useEffect, useRef, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import { urlReplaceState } from "helpers/url";
import withRouter from "hoc/withRouter";
import { IconButton, TextField } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import membersService from "services/admin/membersService";

const List = ({ router: { queryParams } }) => {
  const searchRef = useRef();

  const [search, setSearch] = useState({
    page: queryParams.get("page") || 1,
    showFullInfo: true,
    query: queryParams.get("query") || "",
  });

  const [data, loadData, loading] = useRemoteResource(membersService.getAll, search);

  const onDelete = async (item) => {
    await membersService.delete(item.memberID);
    loadData();
  };

  useEffect(() => {
    // Используется для обновления состояние url
    urlReplaceState(null, search);
  }, [search]);

  const onSubmit = (e) => {
    e.preventDefault();

    const query = String(searchRef.current.value).trim();
    updateSearch({ query });
  };

  const updateSearch = (params) => {
    setSearch({
      ...search,
      ...params,
    });
  };

  return (
    <ListPage pageTitle="Список сотрудников">
      {/* Searching */}
      <form className="search__inputs mt-4 mb-4" onSubmit={onSubmit}>
        <div className="flex">
          <TextField
            type="search"
            placeholder="Поиск по имени или по почте"
            inputRef={searchRef}
            autoComplete="off"
            className="m-w-100"
            style={{ minWidth: 300 }}
          ></TextField>
          <IconButton type="submit" size="large">
            <FaSearch></FaSearch>
          </IconButton>
        </div>
      </form>
      <AppAdminTable
        columnConfig={listConfig}
        data={data?.data || []}
        onDelete={onDelete}
        loading={loading}
      />
      {/* Pagination */}
      {data && <Pagination total={data.total} limit={50} onPageChange={updateSearch} />}
    </ListPage>
  );
};

export default withRouter(List);
