import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { addConfig as config } from "tableColumns/member-columns";
import { rolesAdminService } from "services/admin/rolesService";
import { mapFieldWithOptions } from "helpers/mapFieldWithOptions";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import memberService from "services/admin/membersService";
import { phoneToBackendFormat } from "helpers/utils";

const AddPage = ({ router: { navigate }, data }) => {
  const fieldsWithData = mapFieldWithOptions(config, {
    roleID: { options: data.roleID },
  });

  const onSubmit = async (formData) => {
    try {
      console.log("formData:", formData);
      // clear spaces
      formData.email = String(formData.email).trim();
      formData.name = String(formData.name).trim();

      // В будущем код страны выбирается из списка      
      if (formData.phone) {
        const phoneCountryCode = '7'
        formData.phone = phoneToBackendFormat(formData.phone, phoneCountryCode)
      }

      await memberService.post(formData)
      setTimeout(() => {
        alert("Сотрудник добавлен успешно!");
        navigate(-1);
      }, 1000);
    } catch (ex) {
      console.error(ex)
      alert(ex?.message)
    }
  };

  console.log("fieldsWithData:", fieldsWithData);

  return (
    <CrudPage title="Создание сотрудника">
      <AppForm onSubmit={onSubmit} fields={fieldsWithData} />
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(AddPage, () => {
    return useLoadAll({
      roleID: rolesAdminService.getAll,
    });
  })
);
