import { Button, TextField } from "@mui/material";
import AppTable from "components/AppTable/AppTable";
import React, { useRef, useState } from "react";
import { DebtorAdminService } from "services/admin/debtorService";

export default function Debtor() {
  const [result, setResult] = useState(null);
  const [fetching, setFetching] = useState(false);
  const iinRef = useRef();

  const columnConfig = [
    {
      key: "debtorFullName",
      label: "Должник",
    },
    {
      key: "ipStartDate",
      label: "Дата исполнительного производства",
    },
    {
      key: "disaDepartmentName_ru",
      label: "Орган исполнительного пр-ва, судебный исполнитель",
    },
    {
      key: "hasTravelBan",
      label: "Наличие запрета на выезд из РК по исполнительным производствам ЧСИ/ГСИ",
      formatter: (v) => {
        return v === true ? "Выезд запрещен" : "Не имеется запрет";
      },
    },
  ];

  async function onsubmit(e) {
    try {
      setFetching(true);
      e.preventDefault();
      const iin = iinRef.current.value;
      console.log("value: ", iin);

      const { data } = await DebtorAdminService.findByIIN(iin);
      console.log("content:", data);

      setResult(data.content);
    } catch (ex) {
      alert("Something wrong while getting Debtor");
    } finally {
      setFetching(false);
    }
  }

  return (
    <div>
      <h3 className="m-b-16">
        Поиск в едином реестре должников и временно ограниченных на выезд должников физических лиц,
        должностных лиц юридического лица
      </h3>
      <form onSubmit={onsubmit} className="m-b-32">
        <input ref={iinRef} id="iin-field" placeholder="ИИН" />
        <Button type="submit">Найти</Button>
      </form>

      <section>
        {result && result.length > 0 ? (
          <div>
            <AppTable columnConfig={columnConfig} data={result}></AppTable>
          </div>
        ) : (
          <div>Задолжность отствует</div>
        )}
      </section>
    </div>
  );
}
