import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { addConfig as config } from "tableColumns/tourpacks-columns";
import { mapFieldWithOptions } from "helpers/mapFieldWithOptions";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import tourPacksService from "services/admin/tour-packs.service";

const AddPage = ({ router: { navigate }, data }) => {
  const fieldsWithData = mapFieldWithOptions(config, {
    roleID: { options: data.roleID },
  });

  const onSubmit = async (formData) => {
    console.log("formData:", formData);
    await tourPacksService.post(formData)
    setTimeout(() => {
      alert("Пакет добавлен успешно!");
      navigate(-1);
    }, 1000);
  };

  return (
    <CrudPage title="Создание пакета">
      <AppForm onSubmit={onSubmit} fields={fieldsWithData} />
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(AddPage, () => {
    return useLoadAll({
    });
  })
);
