import { BaseService } from "services/_baseService";
import appAxios from "services/axios";

class OrgsService extends BaseService<any> {
  constructor() {
    super("orgs");
  }

  get = (id: number): Promise<any> => appAxios.get(`/admin/orgs/${id}`);
  post = (data: number): Promise<any> => appAxios.post(`/admin/orgs`, data);
  put = (id: number, data: any): Promise<any> => appAxios.put(`/admin/orgs/${id}`, data);
}

export default new OrgsService();
