import ListPage from "components/ListPage/ListPage";
import { useRemoteResource } from "hooks/useRemoteResource";
import { listConfig } from "tableColumns/tourpacks-columns";
import AppAdminTable from "components/AppAdminTable/AppAdminTable";
import Pagination from "components/Pagination/Pagination";
import withRouter from "hoc/withRouter";
import tourPacksService from "services/admin/tour-packs.service";

const List = ({ router: { queryParams } }) => {
  const [data, loadData, loading] = useRemoteResource(tourPacksService.getAll, '');

  const onDelete = async (item) => {
    await tourPacksService.delete(item.id);
    loadData();
  };

  return (
    <ListPage pageTitle="Тур пакеты">
      <AppAdminTable
        columnConfig={listConfig}
        data={data || []}
        onDelete={onDelete}
        loading={loading}
      />
      {data && <Pagination total={data.total} limit={50} />}
    </ListPage>
  );
};

export default withRouter(List);
