/**
  Convert array to string. Example:
  let list = [{'id: 1', 'name': 'A'}, {id: 2, 'name': 'B'}] 
  => 
  result: 'a, b'

  @returns string
 */
export const arrayToString = (list, key = "name", separator = ", ") => {
  if (!list || !Array.isArray(list)) {
    return "";
  }
  return list.map((x) => (key ? x[key] : x)).join(separator);
};

export function groupBy(arr, key) {
  return arr.reduce(function (acc, x) {
    (acc[x[key]] = acc[x[key]] || []).push(x);
    return acc;
  }, {});
}

/**
 * Find uniq values in array
 *
 */
export function uniq(arr, key) {
  if (!arr) {
    return [];
  }

  return arr.reduce((acc, curr) => {
    if (!acc.includes(curr[key])) {
      acc.push(curr[key]);
    }

    return acc;
  }, []);
}

// phone: (777)200-1991
// В базе данных будет хранится +77772001991
// На UI будет такой формат: (777)200-1991
export const phoneToBackendFormat = (phone, countryCode = '7') => {
  if (!phone) return;
  // Удаляет скобки, пробеи и февизы
  const clearedPhone = String(phone).replace(/[()-\s]/g, '').trim()
  return `${countryCode}${clearedPhone}`
};

/**
 * Converts 7772001991 to (777)200-1991 
 */
export const phoneToUI = (phone) => {

  return String(phone).replace(/^7/, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
}

export const isEmailAddress = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
/**    
 *  Converts 
    data.phone: "87014073428"
    data.language: "English"
    email:"serik.shaikamalov@gmail.com"
    name: "Serik Shaikamalov"
    to =>
    data {
        phone: "87014073428",
        language: "English"
    },
    email: "serik.shaikamalov@gmail.com",
    name: "Serik Shaikamalov"
 */
export const convertToNestedJSON = (input) => {
  if (!input)
    throw new Error('Please provide input data')
  if (typeof input !== "object")
    throw new Error('Input is not object')
  return Object.entries(input).reduce((acc, [key, value]) => {
    if (!value) return acc
    if (key.includes('.')) {
      let properties = key.split('.')
      return Object.assign(acc, {
        [properties[0]]: acc[properties[0]] ?
          Object.assign({}, acc[properties[0]], { [properties[1]]: value }) :
          Object.assign({}, { [properties[1]]: value })
      })
    }
    acc[key] = value
    return acc
  }, {})
}


// takes a {} object and returns a FormData object
export const objectToFormData = function(obj, form, namespace) {
    
  var fd = form || new FormData();
  var formKey;
  
  for(var property in obj) {
    if(obj.hasOwnProperty(property)) {
      
      if(namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }
     
      // if the property is an object, but not a File,
      // use recursivity.
      if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        
        objectToFormData(obj[property], fd, property);
        
      } else {
        
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
      
    }
  }
  
  return fd;
    
};