import { Button, Card, CardActions, CardContent } from '@mui/material';
import { phoneToUI } from 'helpers/utils';
import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import { Link } from 'react-router-dom';
import usersService from 'services/usersService';
import _ from 'lodash'

function Profile({ data }) {
    let member
    if (data.user.members) {
        member = data.user.members.find(x => x.orgID === localStorage.getItem('orgID'))
    }    
    console.log("Member: ", member)

    return (
        <div>
            <Card>
                <CardContent>
                    <div className='flex gap-8'>
                        <div>
                            <img style={{ width: 100, borderRadius: '50%' }} src='/media/images/avatar.jpg' />
                        </div>
                        <div className='profile'>
                            <h3>{_.get(member, 'users.IDCard.name')}</h3>
                            {member.users?.phone && <div className='m-t-16 color-blue'>+7{phoneToUI(member.users.phone)}</div>}
                            <div className='m-t-8'>{member.users.email}</div>
                        </div>
                    </div>
                </CardContent>
                <CardActions>
                    <Link to={`/admin/members/edit/${member.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                </CardActions>
            </Card>

            <Card className='m-t-32'>
                <CardContent>
                    <h3 className='m-b-16'>Является сотрудником</h3>
                    <div className='m-t-8'>
                        <span>Наименование организаций: </span>
                        <span>{member && member.orgs.name}</span>
                    </div>
                    <div className='m-t-8'>
                        <span>Имеет роль: </span>
                        <span>{member && member.roleID}</span>
                    </div>
                </CardContent>
            </Card>

            <Card className='m-t-32'>
                <CardContent>
                    <h3 className='m-b-16'>Данные о паспорте</h3>
                    <div className='m-t-8'>
                        <span>Номер пасспорта: </span>
                        <span>Отсутсвует</span>
                    </div>
                    <div className='m-t-8'>
                        <span>Годен до: </span>
                        <span>Отсутсвует</span>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}


export default withRouter(
    withRemoteDataAndSpinner(Profile, (router) => {
        return useLoadAll({
            user: () => usersService.me(),
        });
    })
);
