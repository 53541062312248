import { Button, InputAdornment, TextField } from '@mui/material'
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import whatsappService from 'services/admin/whatsappService';

export const WhatsappSignin = () => {
    const phoneRef = useRef()
    const [isMatched, setIsmatched] = useState(false)
    const navigate = useNavigate()

    async function onsubmit() {
        try {
            const phone = phoneRef.current;
            if (phone) {
                const clearPhone = String(phone.value).replace(/[()-\s]/g, '').trim()
                console.log('clearPhone: ', clearPhone);
                const countryCode = 7
                const fullPhoneNumber = `${countryCode}${clearPhone}`
                const { data } = await whatsappService.singin(fullPhoneNumber)
                if (data.message) {
                    alert(data.message)
                    setTimeout(() => {
                        navigate(`/auth/whatsapp/verify?phone=${fullPhoneNumber}`)
                    }, 500);
                }
            }
        } catch (ex) {
            console.error('WhatsappSignin ex: ', ex);
            alert('Произошла ошибка, попробуйте позже!', ex?.message)
        }
    }

    return (
        <div className='container m-t-32'>
            <div className='login-form-wrapper'>
                <div className='m-b-32'>
                    <h3>Введите ваш номер телефона Whatsapp</h3>
                </div>
                <TextField inputRef={phoneRef} className='w-100' placeholder='(777)200-1991' onInput={function (e) {
                    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                    if (e.target.value.length === 14) {
                        setIsmatched(true)
                    } else {
                        setIsmatched(false)
                    }
                }} InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src="/media/images/kz.png" style={{ width: 'auto', height: 16 }} />
                        </InputAdornment>
                    ),
                }}>
                </TextField>
                <div className='w-100 m-t-32'>
                    <Button onClick={onsubmit} variant='contained' className='w-100' disabled={!isMatched}>Отправить код</Button>
                </div>
            </div>
        </div>
    )
}
