import inputTypeEnum from "enums/inputTypes";


export const listConfig = [
  {
    key: "name",
    label: "Название",
  },
  {
    key: "price",
    label: "Цена",
  },
  {
    key: "status",
    label: "Статус"
  },
];


export const addConfig = [
  {
    key: "name",
    label: "Название",
  },
  {
    key: "price",
    label: "Цена",
  },
  {
    key: "status",
    label: "Статус",
    inputType: inputTypeEnum.select,
    options: [{ id: 1, name: 'Активный' }]
  },
];

export const editConfig = [
  {
    key: "name",
    label: "Название",
  },
  {
    key: "price",
    label: "Цена",
  },
  {
    key: "status",
    label: "Статус",
    inputType: inputTypeEnum.select,
    options: ['1']
  },
];
