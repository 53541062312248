import { Outlet } from "react-router-dom";
import AddPage from "./pages/Add/Add";
import EditPage from "./pages/Edit/Edit";
import ListPage from "./pages/List/List";

export const adminTourPacksRoutes = [
  {
    path: "tour-packs",
    element: Outlet,
    children: [
      {
        path: "",
        element: ListPage,
      },
      {
        path: "add",
        element: AddPage,
      },
      {
        path: "edit/:id",
        element: EditPage,
      },
    ],
  },
];
