import "./stats.scss";
import UsersCount from "../../components/UsersCount/UsersCount";
import PieChart from "../../components/PieChart/PieChart";
import LineChart from "../../components/LineChart/LineChart";
import MembersCount from "../../components/MembersCount/MembersCount";
import TourPacksCount from "../../components/TourPacksCount/TourPacksCount";
import OrdersCount from "../../components/OrdersCount/OrdersCount";

const MainStatsPage = () => {
  return (
    <div>
      <div className="flexr stats" style={{ gap: "30px" }}>
        <UsersCount />
        <MembersCount />
        {/* <TourPacksCount /> */}
        <OrdersCount />
      </div>

      <section className="m-t-32 m-b-32">
        {/* <CourseStats></CourseStats> */}
      </section>
      <section className="m-t-32 m-b-32">
        {/* <PopularLessons></PopularLessons> */}
      </section>

      <section className="m-t-32 m-b-32 flexr">
        <PieChart />
        <LineChart />
      </section>

      <section>
        {/* <LastCourseComments>  </LastCourseComments> */}
      </section>
    </div>
  );
};

export default MainStatsPage;
