import { Card, CardContent } from '@mui/material'
import { toFullDateName } from 'helpers/dateHelper';
import { phoneToUI } from 'helpers/utils'
import { NavLink } from 'react-router-dom';

export default function CustomerCard({ customer }) {
    return (
        <div>
            <Card>
                <CardContent>
                    <div className='flex gap-8'>
                        <div>
                            <img style={{ width: 100, borderRadius: '50%' }} src={customer.users.photo || `/media/images/avatar.jpg`} />
                        </div>
                        <div className='profile'>
                            <NavLink to={`/admin/users/view/${customer.id}`}>
                                <h3>{customer.users.IDCard.name}</h3>
                            </NavLink>
                            {customer.users?.phone && <div className='m-t-16 color-blue'>+7{phoneToUI(customer.users.phone)}</div>}
                            <div className='m-t-8'>{customer.users.email}</div>
                            <div className='m-t-8 fs-14'>Является клиентом с {toFullDateName(customer.createdAt)}</div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
