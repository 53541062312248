import { BaseService } from "services/_baseService";
import appAxios from "services/axios";

class WhatsappAdminService extends BaseService<any> {
  constructor() {
    super("whatsapp");
  }
  async connect() {
    return appAxios.get(`/admin/orgs/${localStorage.getItem("orgID")}/whatsapp/connect`);
  };

  async singin(phone: string) {
    return appAxios.post(`/auth/whatsapp/sign`, { phone });
  }
  async verify(phone: string, password: string) {
    return appAxios.post(`/auth/whatsapp/verify`, { phone, password });
  }
}

export default new WhatsappAdminService();
