import { createSelector } from "@reduxjs/toolkit";

export const getOrg = createSelector(
  (store) => store.auth.user,
  (user) => {
    if (!user) {
      return null
    }

    if (!user.orgs) {
      return null
    }

    return user?.orgs[0]
  }
);
