import { useRemoteResource } from 'hooks/useRemoteResource';
import whatsappAdminService from '../../../services/admin/whatsappService'
import { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode'

export default function WhatsappConnect() {
    const canvasRef = useRef()
    const [data, loadData, loading] = useRemoteResource(whatsappAdminService.connect);
    const [error, setError] = useState(null)

    useEffect(() => {
        if (data) {
            QRCode.toCanvas(canvasRef.current, data.qrCode, { small: true }, (error) => {
                if (error) {
                    console.error(error)
                    setError(error)
                    return
                }
                console.log('success!');
            })
        }
    }, [data])

    if (loading) return <div>Loading...</div>;

    if (!data) {
        return <div>QR Code not found</div>
    }

    if (error) {
        return <>
            <h3>Интергация с Whatsapp. Данный раздел только для администратора организаций.</h3>
            <p>{error}</p>
        </>
    }

    return (
        <div>
            <h3>Интергация с Whatsapp. Данный раздел только для администратора организаций.</h3>
            <canvas id="canvas" ref={canvasRef}></canvas>
        </div>
    )
}
