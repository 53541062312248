import { adminUsersRoutes } from "./Users/users-routes";
import AdminLayout from "./AdminLayout";
import Debtor from "./Users/pages/Debtor/Debtor";
import { adminMembersRoutes } from "./Members/members-routes";
import EmptyPage from "components/EmptyPage/EmptyPage";
import Settings from "./Settings/Settings";
import { adminTourPacksRoutes } from "./TourPacks/tourpacks-routes";
import Invoices from "./Invoces/pages/Invoices";
import WhatsappConnect from "./Whatsapp/WhatsappConnect";
import { adminStatsRoutes } from "./Stats/stats-router";
import Profile from "./Profile/Profile";

export const adminRoutes = [
  {
    path: "admin",
    element: AdminLayout,
    name: "Admin Panel",
    children: [
      ...adminUsersRoutes,
      {
        element: Debtor,
        path: "debtors",
        label: "Реестр должникиов",
      },
      {
        element: EmptyPage,
        path: "orders/list",
      },
      {
        element: EmptyPage,
        path: "trips",
      },
      {
        element: EmptyPage,
        path: "stats",
      },
      {
        element: Settings,
        path: "settings",
      },
      {
        element: Invoices,
        path: "invoices",
      },
      {
        element: EmptyPage,
        path: "comments",
      },
      {
        element: WhatsappConnect,
        path: "whatsapp/connect",
      },
      {
        element: Profile,
        path: "profile",
      },
      ...adminMembersRoutes,
      ...adminTourPacksRoutes,
      ...adminStatsRoutes
    ],
  },
];
