import { Autocomplete, Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import TextEditor from "components/TextEditor/TextEditor";
import inputTypeEnum from "enums/inputTypes";
import { toNativeDate } from "helpers/dateHelper";
import { useCallback } from "react";
import "./form.scss";
import { phoneToUI } from "helpers/utils";
import _ from 'lodash'

/**
 *
 * Supports: Text, Number, Select, Multiple Select, Checkbox, RichText, Date
 * @param data - for data for autofill
 * @returns
 */
const AppForm = ({ onSubmit, data, fields, formProps = {} }) => {

  const defaultValue = useCallback(
    (col) => {
      const selected = data?.[col.key];

      console.log("selected:", selected);

      if (col.options && selected) {
        if (col.multiple) {
          // Multiple selection
          return col.options.filter((x) => selected.map((s) => s.id).includes(x.id));
        } else {
          // Single selection
          return col.options.find((x) => {
            if (typeof selected === "object") {
              return x.id === selected["id"];
            }
            return x.id == selected;
          });
        }
      }

      // When no default
      return Boolean(col.multiple) ? [] : "";
    },
    [data]
  );

  function handleSubmit(e) {
    e.preventDefault()
    let formData = new FormData(e.target)

    // Remove file input if it's empty    
    Object.entries(Object.fromEntries(formData)).forEach(([k, v]) => {
      console.log(`${k}:${v}`);
      if (v instanceof File && v.size === 0 && v.name === "") {
        // Если файл не выбран то отправляем предыдующую значение        
        const prevValue = _.get(data, k)
        formData.set(k, prevValue || "")
      }
    })
    onSubmit(formData, e)
  }

  return (
    <form onSubmit={handleSubmit} {...formProps}>
      <Box sx={{ marginBottom: "20px" }}>
        {fields &&
          fields.map((f, fieldIndex) => {
            const commonProps = {
              required: f.required === true,
            };

            if ([inputTypeEnum.text, inputTypeEnum.email].includes(f.inputType)) {
              return (
                <div className="form-input" key={f.key}>
                  <div className="form-input__input">
                    <TextField
                      name={f.key}
                      id={f.key}
                      defaultValue={_.get(data, f.key) || f?.defaultValue}
                      className="w-100"
                      autoComplete="false"
                      type={f.inputType}
                      required={f.required}
                      label={f.label}
                    ></TextField>
                  </div>
                </div>
              );
            }
            if (f.inputType === inputTypeEnum.tel) {
              const phoneValue = phoneToUI(_.get(data, f.key) || f?.defaultValue || "")
              console.log("phoneValue:", phoneValue);

              return (
                <div className="form-input" key={f.key}>
                  <div className="form-input__input">
                    <TextField
                      label={f.label}
                      name={f.key}
                      id={f.key}
                      defaultValue={phoneValue}
                      className="w-100"
                      autoComplete="false"
                      type={f.inputType}
                      onInput={function (e) {
                        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                      }}
                      InputProps={{
                        startAdornment: (
                          <span>+7</span>
                        ),
                      }}
                    ></TextField>
                  </div>
                </div>
              );
            }
            if (f.inputType === inputTypeEnum.hidden) {
              return (
                <div className="form-input" key={f.key}>
                  <div className="form-input__input">
                    <input
                      type="hidden"
                      name={f.key}
                      id={f.key}
                      defaultValue={_.get(data, f.key) || f?.defaultValue}
                      autoComplete="false"
                    />
                  </div>
                </div>
              );
            }
            if (f.inputType === inputTypeEnum.select) {
              return (
                <div
                  className="form-input"
                  key={f.key}
                  data-key={f.key}
                  data-value={JSON.stringify(_.get(data, f.key))}
                  data-default-value={_.get(data, f.key)}
                >
                  <div className="form-input__label">
                    <label htmlFor="name">{f.label}</label>
                  </div>
                  <div className="form-input__input">
                    <Autocomplete
                      name={f.key}
                      defaultValue={defaultValue(f)}
                      options={f?.options || []}
                      getOptionLabel={(option) => option.name || ""}
                      multiple={Boolean(f.multiple)}
                      onChange={(_, data) => {
                        console.log("data:", data);
                      }}
                      renderInput={(params) => {
                        return <TextField {...params} {...commonProps} />;
                      }}
                    />
                  </div>
                </div>
              );
            }
            if (f.inputType === inputTypeEnum.checkbox) {
              return (
                <div className="form-input" key={f.key}>
                  <div className="form-input__label">
                    <input
                      type="checkbox"
                      name={f.key}
                      id={f.key}
                      defaultChecked={_.get(data, f.key)}
                    />
                    <label className="m-l-8" htmlFor={f.key}>
                      {f.label}
                    </label>
                  </div>
                </div>
              );
            }
            if (f.inputType === inputTypeEnum.richText) {
              return (
                <div className="form-input" key={f.key}>
                  <div className="form-input__label">
                    <label htmlFor="name">{f.label}</label>
                  </div>
                  <div className="form-input__input">
                    <TextEditor
                      value={_.get(data, f.key) || ""}
                    ></TextEditor>
                  </div>
                </div>
              );
            }
            if (f.inputType === inputTypeEnum.date) {
              return (
                <div className="form-input" key={f.key}>
                  <div className="form-input__label">
                    <label htmlFor={f.key}>{f.label}</label>
                  </div>
                  <div className="form-input__input">
                    <input
                      name={f.key}
                      id={f.key}
                      defaultValue={
                        _.get(data, f.key)
                          ? toNativeDate(new Date(_.get(data, f.key)))
                          : toNativeDate(new Date())
                      }
                      className="w-100"
                      autoComplete="false"
                      type="date"
                      readOnly={f.readOnly}
                      {...commonProps}
                    />
                  </div>
                </div>
              );
            }
            if (f.inputType === inputTypeEnum.file) {
              return (
                <div key={f.key} className="flex flex-col field-file">
                  <div className="preview">
                    <label>{f.label}</label>
                    {_.get(data, f.key) ? (
                      <div style={{ width: 100, height: 'auto', marginTop: "1rem" }}>
                        <img src={_.get(data, f.key)} alt="" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {" "}
                    <input
                      type="file"
                      id={f.key}
                      name={f.key}
                      onChange={(e) => {
                        console.log("onChange: file", { e, id: f.key, element: e.target });

                        // const file = document.querySelector(`#${f.key}`).files[0];
                        const file = e.target.files[0]
                        const max = 5 * 1000 * 1000; // 5MB
                        console.log("File:", file);
                        if (file && file.size > max) {
                          alert(
                            "Вы не можете загружать большие файлы! Пожалуйста используйте файл маленького размера"
                          );
                        }

                        const fileReader = new FileReader();
                        const previewNode = e.target.parentNode.parentNode.querySelector('.preview')
                        if (!previewNode.querySelector('img')) {
                          const img = document.createElement('img')
                          const imgWrapper = document.createElement('div')
                          imgWrapper.style = 'width: 100px; height: auto; margin-top: 1rem;'
                          imgWrapper.appendChild(img)
                          previewNode.appendChild(imgWrapper)
                        }
                        console.log("document.parentElement:", previewNode);

                        const previewImage = previewNode.querySelector('img')

                        fileReader.onload = event => {
                          previewImage.setAttribute('src', event.target.result);
                        }
                        fileReader.readAsDataURL(file);
                      }}
                    />
                  </div>
                </div>
              );
            }
            if (f.label) {
              if (typeof f.label == 'function') {
                return <div key={f.key || fieldIndex}>{f.label({ key: f.key })}</div>
              }
              return <h3 key={f.key || fieldIndex}>{f.label}</h3>
            }
            return <div key={f.key || fieldIndex}></div>;
          })}
      </Box>
      <Button type="submit" variant="contained">
        Save
      </Button>
    </form>
  );
};

export default AppForm;
//
