import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { mapFieldWithOptions } from "helpers/mapFieldWithOptions";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import { rolesAdminService } from "services/admin/rolesService";
import { editConfig as config } from "tableColumns/member-columns";
import { phoneToBackendFormat, phoneToUI } from "helpers/utils";
import membersService from "services/admin/membersService";

const EditPage = ({
  router: {
    navigate,
    params: { id },
  },
  data: { member },
}) => {
  const fieldsWithData = mapFieldWithOptions(config, {
    roleID: { options: member.roleID },
  });

  const onSubmit = async (formData) => {
    try {
      formData.email = String(formData.email).toLowerCase().trim();
      formData.name = String(formData.name).trim();

      if (formData.phone) {
        const phoneCountryCode = '7'
        formData.phone = phoneToBackendFormat(formData.phone, phoneCountryCode)
      }

      await membersService.put(id, formData)
      setTimeout(() => {
        alert("Сотрудник обновлен успешно!");
        navigate(-1);
      }, 1000);
    } catch (ex) {
      console.error(ex)
      alert(ex?.message)
    }
  };

  return (
    <CrudPage title="Редактирование пользователя">
      <AppForm onSubmit={onSubmit} data={member.users} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(EditPage, (router) => {
    return useLoadAll({
      roleID: rolesAdminService.getAll,
      member: () => membersService.get(router.params.id),
    });
  })
);
