import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import customersService from "services/admin/customersService";
import inputTypeEnum from "enums/inputTypes";

const UserForm = ({ router: { navigate }, data: { customer } }) => {
  const fieldsWithData = [
    {
      key: "phone",
      label: "Номер телефона Whatsapp. Будет использоваться как основной вход в систему",
      required: true,
      inputType: inputTypeEnum.tel,
    },
    {
      key: "email",
      label: "Почта пользователя если имеется. Например: serik@gmail.com",
      inputType: inputTypeEnum.text,
    },
    {
      key: "photo",
      label: "Фото клиента. Размер должен быть 4x6. Обязательно на белом фоно",
      required: false,
      placeholder: 'Добавьте фото пользователя',
      inputType: inputTypeEnum.file,
    },
    {
      label: (props) => <h3 className="m-t-32 p-t-32" {...props}>Данные об удостоверение личности</h3>
    },
    {
      key: "IDCard.name",
      label: "ФИО(полностью как указан в удостоверение личности)",
      required: true,
      placeholder: 'Введите ФИО пользователя',
      inputType: inputTypeEnum.text,
    },
    {
      key: "IDCard.iin",
      label: "ИИН пользователя",
      inputType: inputTypeEnum.text,
      required: true
    },
    {
      key: "IDCard.expiredAt",
      label: "Годен до",
      required: true,
      inputType: inputTypeEnum.date,
    },
    {
      key: "IDCard.photoFront",
      label: "Фото удостоверение личности(передняя часть)",
      required: false,
      inputType: inputTypeEnum.file,
    },
    {
      key: "IDCard.photoBack",
      label: "Фото удостоверение личности(задняя часть)",
      required: false,
      inputType: inputTypeEnum.file,
    },
    {
      label: (props) => <h3 className="m-t-32 p-t-32"  {...props}>Данные о паспорте</h3>
    },
    {
      key: "passport.name",
      label: "ФИО(полностью как указан в паспорте)",
      required: true,
      placeholder: 'Введите ФИО пользователя',
      inputType: inputTypeEnum.text,
    },
    {
      key: "passport.number",
      label: "Номер паспорта",
      inputType: inputTypeEnum.text,
      required: true
    },
    {
      key: "passport.expiredAt",
      label: "Годен до",
      required: true,
      inputType: inputTypeEnum.date,
    },
    {
      key: "passport.photo",
      label: "Фото паспорта",
      required: false,
      inputType: inputTypeEnum.file,
    }
  ]

  const onSubmit = async (formData) => {      
    try {                         
      console.log("formData", Object.fromEntries(formData));      

      if (customer) {
        await customersService.put(customer.id, formData)
        alert('Пользователь обновлен успешно!')
      } else {
        await customersService.post(formData)
        alert('Пользователь добавлен успешно!')
      }

      // setTimeout(() => {
      //   navigate('/admin/users')
      // }, 500);
    } catch (ex) {
      console.error('Something wrong while adding user', ex)
      alert(ex?.message)
    }
  };

  console.log("fieldsWithData:", fieldsWithData);

  return (
    <CrudPage title={customer ? `Изменение клиента` : `Добавление нового клиента`}>
      <AppForm onSubmit={onSubmit} fields={fieldsWithData} data={customer?.users} />
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(UserForm, (router) => {
    return useLoadAll({
      customer: () => router.params.id ? customersService.get(router.params.id) : null,
    });
  })
);
