import inputTypeEnum from "enums/inputTypes";
import { TableColumns } from "../helpers/tableColumns";
import { arrayToString } from "helpers/utils";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const listConfig = [
  TableColumns.index,
  {
    key: "users.IDCard.name",
    label: "ФИО",
  },
  {
    key: "users.email",
    label: "Email",
    inputType: inputTypeEnum.email,
  },
  {
    key: "users.phone",
    label: "Тел.",
    required: true,
    inputType: inputTypeEnum.tel,
  },
  {
    key: "roles.name",
    label: "Роль",
  },
  TableColumns.createdDate
];

export const listConfigSuperAdmin = [...listConfig, TableColumns.createdBy];

export const addConfig = [
  TableColumns.fullName,
  TableColumns.email,
  TableColumns.phone,
  {
    key: "roleID",
    label: "Роли",
    inputType: inputTypeEnum.select,
    options: [],
    multiple: false,
  },
];

export const editConfig = [
  {
    key: "IDCard.name",
    label: "ФИО(полностью как указан в удостоверение личности)",
    required: true,
    placeholder: 'Введите ФИО пользователя',
    inputType: inputTypeEnum.text,
  },
  TableColumns.email,
  TableColumns.phone,
];
