import { Outlet } from "react-router-dom";
import ListPage from "./pages/List/List";
import AddBulk from "./pages/AddBulk/AddBulk";
import Customer from "../Customer/Customer";
import UserForm from "./pages/UserForm/UserForm";

export const adminUsersRoutes = [
  {
    path: "users",
    element: Outlet,
    children: [
      {
        path: "",
        element: ListPage,
      },
      {
        path: "add",
        element: UserForm,
      },
      {
        path: "edit/:id",
        element: UserForm,
      },
      {
        path: "view/:id",
        element: Customer,
      },
      {
        path: "add-bulk",
        element: AddBulk,
      }
    ],
  },
];
