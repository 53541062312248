import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { addConfig as config } from "tableColumns/org-columns";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import orgsService from "services/admin/orgsService";
import { useDispatch } from "react-redux";
import { getMeAction } from "redux/actions/authActions";

const Settings = ({ router: { navigate }, data }) => {
  const dispatch = useDispatch();

  const onSubmit = async (formData) => {
    try {
      await orgsService.put(localStorage.getItem('orgID'), formData);
      dispatch(getMeAction())
      alert('Организация успешно обновлена!')      
    } catch (ex) {
      console.error(`ex:`, ex)
      alert('Вышла ошибка во время обновления организаций')
    }
  };

  return (
    <CrudPage title="Изменение организаций">
      <AppForm onSubmit={onSubmit} data={data.org} fields={config} />
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(Settings, (router) => {
    return useLoadAll({
      org: () => orgsService.get(localStorage.getItem('orgID')),
    });
  })
);
