import ListPage from "components/ListPage/ListPage";
import { useRemoteResource } from "hooks/useRemoteResource";
import { useEffect, useRef, useState } from "react";
import { urlReplaceState } from "helpers/url";
import withRouter from "hoc/withRouter";
import { Link } from "react-router-dom";
import { Button, CircularProgress, IconButton, TextField } from "@mui/material";
import { FaFileAlt, FaSearch } from "react-icons/fa";
import customersService from "services/admin/customersService";
import CustomerCard from "components/CustomerCard/CustomerCard";

function BulkButton() {
  return (
    <span>
      <Link to="add-bulk">
        <Button variant="contained">
          <FaFileAlt /> &nbsp; Добавить через CSV
        </Button>
      </Link>
    </span>
  );
}

const List = ({ router: { queryParams } }) => {
  const searchRef = useRef();

  const [search, setSearch] = useState({
    page: queryParams.get("page") || 1,
    showFullInfo: true,
    query: queryParams.get("query") || "",
  });

  const [data, loadData, loading] = useRemoteResource(customersService.getAll, search);


  useEffect(() => {
    // Используется для обновления состояние url
    urlReplaceState(null, search);
  }, [search]);

  const onSubmit = (e) => {
    e.preventDefault();

    const query = String(searchRef.current.value).trim();
    updateSearch({ query });
  };

  const updateSearch = (params) => {
    setSearch({
      ...search,
      ...params,
    });
  };

  return (
    <ListPage pageTitle="Список клиентов" bulk={BulkButton}>
      {/* Searching */}
      <form className="search__inputs mt-4 mb-4" onSubmit={onSubmit}>
        <div className="flex">
          <TextField
            type="search"
            placeholder="Поиск по имени или по почте"
            inputRef={searchRef}
            autoComplete="off"
            className="m-w-100"
            style={{ minWidth: 300 }}
          ></TextField>
          <IconButton type="submit" size="large">
            <FaSearch></FaSearch>
          </IconButton>
        </div>
      </form>
      <div className="m-t-32">
        
        {
         loading ? <div className="text-center"><CircularProgress></CircularProgress></div> :  data?.data?.map((c, inx) => {
            return <div className="m-b-16">
              <CustomerCard customer={c} key={inx}></CustomerCard>
            </div>
          })
        }
      </div>
    </ListPage>
  );
};

export default withRouter(List);
